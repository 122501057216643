.navbar-brand {
  cursor: pointer;
}

.navbar-brand.nav-link {
  margin: 0;
}

.nav-link {
  margin-left: 20px;
}
