.login-container .modal-header {
  border-bottom: none !important;
  z-index: 999;
}

.login-container .modal-body {
  justify-content: center;
  text-align: center;
  margin-top: -65px;
  z-index: 1;
}

.login-container h2 {
  padding-bottom: 20px;
}

.login-container Button > img {
  height: 30px;
  width: 30px;
  background-color: white;
  border-radius: 2px;
  margin-left: 5px;
}

.login-container .login-button {
  margin-bottom: 15px;
  margin-left: auto;
  margin-right: auto;
  max-width: 300px;
}

.login-container .modal__image {
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
}

.modal__image > img {
  width: 70%;
  height: auto;
  max-height: 180px;
  border-radius: 30px;
}
