body {
  margin: 0;
  font-family: Open Sans, Helvetica, Arial, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: linear-gradient(
    rgba(8, 179, 248, 0.6),
    rgba(107, 249, 127, 0.6)
  );
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 100vh;
  overflow: scroll;
}

* {
  box-sizing: border-box;
}

#root {
  position: relative;
}
