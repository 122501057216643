.App {
	text-align: center;
}

/* --------- App --------- */
.container {
	padding: 0px !important;
	margin-left: 0px !important;
	margin-right: 0px !important;
	max-width: 100% !important;
}

.row {
	margin: 0 !important;
}

.app-container {
	margin-top: 56px;
	position: relative;
}

#overlay {
	background: #ffffff;
	color: #666666;
	position: fixed;
	height: 100%;
	width: 100%;
	z-index: 5000;
	top: 0;
	left: 0;
	float: left;
	text-align: center;
	padding-top: 25%;
	opacity: 0.8;
}

.login-loading {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-content: center;
	margin: 5px;
}

.login-loading > p {
	margin-bottom: 0px;
	margin-left: 10px;
}

.modal-backdrop {
	height: 115vh !important;
	overflow-y: visible;
}

/* --------- LEGAL PAGES --------- */
.legal {
	height: 100%;
	padding-bottom: 30px;
}

/* --------- LOBBY PAGES --------- */
.lobby-container {
	padding: 15px;
	color: white;
}

.lobby-title,
.game-title {
	text-align: center;
	position: relative;
}

.lobby-title > h1 {
	margin-bottom: 0px;
}

.game-title {
	display: flex;
}

.game-title > h3 {
	margin-bottom: 0px;
}

.lobby-action,
.lobby-body {
	margin-top: 35px;
}

.form-label,
.form-check-label {
	text-shadow: 0px 0px 6px #000000bf !important;
}

.close-button {
	height: 100%;
	overflow: auto;
	position: absolute;
	left: 0;
}

.inbox-button {
	height: 100%;
	overflow: auto;
	position: absolute;
	right: 15px;
	color: white;
}

.settings-button {
	height: 100%;
	overflow: auto;
	position: absolute;
	right: 55px;
	color: white;
}

.close-icon,
.inbox-icon,
.settings-icon {
	position: relative;
	top: 50%;
	transform: translateY(-50%);
}

/* --------- FORMS --------- */
span.errors {
	font-weight: bold;
	color: red;
}

.radio__description {
	background-color: white;
	color: #333;
	padding: 8px;
	border-radius: 10px;
	font-size: 85%;
}

.radio__description > label {
	font-weight: bold;
}

.radio__description > p {
	margin-bottom: 0px;
}

/* --------- TOAST --------- */
.toast.toast-warning {
	background-color: orange;
}

.app-container .toast {
	position: absolute;
	background-color: orange;
	color: white;
	margin: 0px auto;
	z-index: 100;
	left: 50%;
	top: 10px;
	margin-left: -100px;
	width: 200px;
}
.app-container .toast-header {
	background-color: orange;
	color: white;
}

.app-container .toast-body {
	width: 100%;
	position: relative;
	display: flex;
	flex-direction: column;
}

.app-container .toast-body > button {
	background-color: white;
	color: #ffa500;
	border: none;
}
.app-container .toast-body > button:active {
	background-color: #ce8600 !important;
}

/* --------- OFFLINE --------- */
.offlineWarning {
	background-color: orange;
	color: white;
	text-align: center;
}

/* --------- EMOJIES --------- */
.emoji {
	font-weight: 300;
}
