.rules-container .modal-header {
  border-bottom: none !important;
  z-index: 999;
}

.rules-container .modal-body {
  justify-content: left;
  text-align: left;
  margin-top: -65px;
  z-index: 1;
}

.rule__options > p.hint {
  margin-bottom: 5px;
  font-weight: 300;
}
